import React from 'react'
import { SbBlokData, storyblokEditable } from '@storyblok/react'
import UtilButtonV2 from '@/v2/storyblok/utils/UtilButtonV2'
import BaseImageV2 from '@/v2/base/BaseImageV2'
import UtilLinkV2 from '../utils/UtilLinkV2'
import classNames from 'classnames'
import Icon from '@/components/Icon'

type Props = {
  blok: SbBlokData & ISbSectionActionCardV2
}

const getClasses = (background: BackgroundColor) => {
  const backgroundMap = {
    'green-dark': 'bg-v2-background-green-dark',
    beige: 'bg-v2-background-beige',
    blue: 'bg-v2-background-blue',
    white: 'bg-v2-background-white',
  }

  const textMap = {
    'green-dark': 'text-v2-background-white',
    beige: 'text-v2-text-secondary',
    blue: 'text-v2-text-primary',
    white: 'text-v2-text-secondary',
  }

  const backgroundClasses = backgroundMap[background] || backgroundMap['white']
  const titleColor = background === 'green-dark' ? 'text-v2-background-white' : 'text-v2-text-primary'
  const linkColor = background === 'green-dark' ? 'white' : 'primary'
  const textColor = textMap[background] || textMap['white']

  return { backgroundClasses, titleColor, textColor, linkColor }
}

const SectionActionCardV2 = ({ blok }: Props) => {
  const hasImage = !!blok.image.filename
  const { backgroundClasses, titleColor, textColor, linkColor } = getClasses(blok.background)

  return (
    <div className="container-v2 py-16 lg:py-24" {...storyblokEditable(blok)}>
      <div
        className={classNames('container-v2 overflow-hidden rounded-5xl px-0 ', backgroundClasses, {
          'border border-v2-foreground-static-tertiary': blok.border,
        })}
      >
        {blok.mobile_image?.filename && (
          <div className="w-full md:hidden">
            <BaseImageV2
              src={blok.mobile_image.filename}
              alt={blok.mobile_image.alt}
              breakPointsWidthMap={{ 0: 800 }}
              className="w-full [&_img]:w-full [&_img]:object-cover"
            />
          </div>
        )}
        <div className="flex flex-col gap-x-20 gap-y-8 px-7 py-6 md:p-12 lg:flex-row lg:p-20">
          <div
            className={classNames('flex flex-col gap-8', {
              'max-w-4xl lg:w-4/5': hasImage,
            })}
          >
            <div className="flex flex-col gap-2">
              <h2 className={classNames('heading-3 whitespace-pre-line', titleColor)}>
                {blok.title}
                {blok.subtitle && (
                  <>
                    <br /> <span className={classNames('opacity-75', textColor)}>{blok.subtitle}</span>{' '}
                  </>
                )}
              </h2>

              {!!blok.text && <p className={classNames('body-1', textColor)}>{blok.text}</p>}
            </div>

            {!!blok.list?.length && (
              <div className="flex flex-col gap-2">
                {blok.list.map((item) => (
                  <div key={item._uid} className={classNames('flex items-center gap-2', titleColor)}>
                    <Icon name={item.icon} className="h-6 w-6 shrink-0" />
                    <p className=" body-1">{item.text}</p>
                  </div>
                ))}
              </div>
            )}
            {!!blok.bottom_text && <p className={classNames('body-1', titleColor)}>{blok.bottom_text}</p>}

            {!!blok.button?.length && (
              <div className="w-full md:w-fit">
                {blok.button.map((button, index) => (
                  <UtilButtonV2 key={index} blok={button} />
                ))}
              </div>
            )}
          </div>

          {hasImage && (
            <div className="flex w-full flex-col items-center gap-3 lg:w-1/5">
              <div>
                <BaseImageV2
                  focus={blok.image.focus}
                  src={blok.image.filename}
                  alt={blok.image.alt}
                  breakPointsWidthMap={{ 0: 210 }}
                />
              </div>

              {blok.image_link.map((link, index) => (
                <UtilLinkV2 color={linkColor as 'white' | 'primary' | undefined} key={index} blok={link} size="sm" />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SectionActionCardV2
